import React from "react";
import { Container, Row, Col, Button } from "reactstrap";

import "react-circular-progressbar/dist/styles.css";
import SpeakerCard from "./SpeakerCard";

function TextGrid() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;

  return (
    <>
      <section id="about">
        <div
          style={{
            background: "#000",
            backgroundSize: "cover",
            backgroundColor: "#000",
            backgroundImage: `linear-gradient(180deg, rgba(152,61,4,1) 0%, rgba(230,95,4,1) 37%)`,
            backgroundRepeat: "no-repeat",
          }}
          className="section pt-0"
          data-parallax={true}
          ref={pageHeader}
        >
          <Container>
            <Row className="">
              <Col
                md={12}
                xs={12}
                style={{
                  // backgroundImage: size && `url(${require("assets/images/text1.png")})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "none",
                }}
              >
                <div
                  className="text-dark"
                  style={{ padding: size ? "0rem 1rem 3rem 1rem" : "3rem 1rem 4rem 1rem" }}
                >
                  <h1
                    className="header-text text-left text-white"
                    style={{ fontSize: size && "4rem" }}
                  >
                    Conference Overview
                  </h1>
                  {size ? (
                    <h3 className="text-400 text-white text-left">
                      Welcome to <b>"We the Women," </b>a dynamic and inspirational conference
                      designed to celebrate and empower women leaders from various fields. This
                      groundbreaking event will bring together renowned women speakers to share
                      their expertise, experiences, and insights on diverse topics, encouraging
                      collaboration and fostering positive change for women worldwide. Our goal is
                      to create an inclusive space where women can network, learn, and inspire each
                      other to break barriers and make a lasting impact.
                    </h3>
                  ) : (
                    <h4 className="text-400 text-white text-left">
                      Welcome to <b>"We the Women," </b>a dynamic and inspirational conference
                      designed to celebrate and empower women leaders from various fields. This
                      groundbreaking event will bring together renowned women speakers to share
                      their expertise, experiences, and insights on diverse topics, encouraging
                      collaboration and fostering positive change for women worldwide. Our goal is
                      to create an inclusive space where women can network, learn, and inspire each
                      other to break barriers and make a lasting impact.
                    </h4>
                  )}

                  <br />
                  <h1
                    className="quote-text text-white text-center"
                    style={{ fontSize: size ? "6rem" : "3rem" }}
                  >
                    Be prepared to leave energized, equipped, empowered, and inspired!
                  </h1>
                </div>
              </Col>
            </Row>
          </Container>
          <Container className=" mb-3">
            <Row className="justify-content-center ">
              <Col md={6} xs={12} className="align-self-top py-0 pb-5">
                <h1
                  className={`text-400 text-white pl-2`}
                  style={{ fontSize: size ? "3.5rem" : "2rem" }}
                >
                  A Confluence dedicated to helping women connect, inspire, learn and lead.
                </h1>
              </Col>
              <Col md={6} xs={12} className="align-self-top ">
                <img src={require("assets/images/text.png")} width="100%" alt="shashi" />
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      <section id="speakers">
        <SpeakerCard />
      </section>
      <section id="attend">
        <div
          style={{
            background: "#000",
            backgroundImage: size && `url(${require("assets/images/image4.png")})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          className={"section"}
          data-parallax={true}
          ref={pageHeader}
        >
          <Container>
            <Row className="justify-content-start">
              <Col
                md={7}
                xs={12}
                className="align-self-end"
                style={{
                  // backgroundImage: size && `url(${require("assets/images/text1.png")})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "none",
                }}
              >
                <div className="">
                  <h1 className="text-300 text-white text-left mb-4">CONFERENCE OBJECTIVES</h1>

                  <Container fluid>
                    <Row>
                      {content1.map((c) => (
                        <Col lg={12} key={c.title} className="border-bottom py-2 border-left">
                          <h3 className="text-600 text-white">
                            <b>{c.title}</b> {c.description}
                          </h3>
                        </Col>
                      ))}
                    </Row>
                  </Container>
                  <br />
                  <Button
                    href="/register"
                    className="navbar-item-custom text-400 text-dark rounded-0 "
                    style={{
                      backgroundColor: "#e65f04",
                      border: "2px solid #000",
                    }}
                    size="lg"
                  >
                    <span
                      style={{
                        color: "#000",
                        textTransform: "none",
                        fontWeight: 700,
                        fontSize: "16px",
                      }}
                      className="text-600"
                    >
                      REGISTER NOW <i class="fa fa-arrow-right" />
                    </span>
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
}

export default TextGrid;

const content1 = [
  {
    title: "CELEBRATE",
    description: "the achievements and contributions of women leaders across various industries",
  },
  {
    title: "PROMOTE",
    description: "gender equality and women's empowerment in all spheres of life",
  },
  {
    title: "INSPIRE",
    description:
      "attendees with motivational stories and experiences from successful women pioneers",
  },
  {
    title: "FACILITATE",
    description:
      "networking opportunities for women to build meaningful connections and collaborations",
  },
  {
    title: "ENCOURAGE",
    description: "dialogue and discussions on pressing issues affecting women's progress",
  },
];
