import React from "react";

import Footer from "components/Footer.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";

import { Container, Row } from "reactstrap";
import Navbar from "components/Navbar.js";


function PrivacyPolicy() {
  return (
    <>

      <Navbar />
      <ProfilePageHeader title="Terms and Conditions" bgColor="#fff" />
      <div className="section profile-content mt-3" style={{ padding: 0 }}>
        <Container>
          <Row></Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
