export const sponsors2024 = [
    {
        "id": 1,
        "name": "Novartis",
        "slug": "Novartis",
        "bio": "",
        "image": "/images/sponsors/1697034615015.png",
        "type": "Healthcare Partner",
        "url": "https://www.novartis.com/",
        "panel": 3,
        "size": 4,
        "priority": 1,
        "isActive": false
    },
    {
        "id": 2,
        "name": "Franklin Templeton",
        "slug": "Franklin Templeton",
        "bio": "",
        "image": "/images/sponsors/FranklinTempleton.png",
        "type": "Lead Sponsor",
        "url": "https://www.franklintempleton.com/",
        "panel": 3,
        "size": 4,
        "priority": 1,
        "isActive": false
    },
    {
        "id": 3,
        "name": "Associate Partner",
        "slug": "Associate Partner",
        "bio": "",
        "image": "/images/sponsors/fleetline.webp",
        "type": "Associate Partner",
        "url": "https://fleetlineshipping.com",
        "panel": 3,
        "size": 4,
        "priority": 1,
        "isActive": false
    },
];