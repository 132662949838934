import React, { useContext } from "react";

import { Container, Row, Col, Card } from "reactstrap";

import { DataContext } from "DataContainer";
import { groupBy } from "views/content";
import { sponsors2024 } from "confiq";

function Sponsor() {
  const { sponsors: allSponsors } = useContext(DataContext);
  const rows = groupBy("panel")(allSponsors);
  if (Object.keys(rows).length < 1) {
    return null;
  }
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",
        }}
        className="section text-center"
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1
                className="quote-text  text-center text-white"
                style={{ fontSize: size ? "6rem" : "3rem" }}
                text-center
              >
                2024 SPONSORS
              </h1>
            </Col>
          </Row>
          <Row>
            {sponsors2024.map((s) => (
              <Col lg={s.size} xs={6} className={"ml-auto mr-auto pb-3"}>
                <h2
                  className="quote-text  text-center text-white"
                  style={{ fontSize: "4rem", marginBottom: "1.5rem" }}
                  text-center
                >
                  {s.type}
                </h2>
                <a href={s.url} target="_BLANK" rel="noreferrer">
                  <Card className="rounded-0">
                    <Container fluid>
                      <Row>
                        <Col lg={12}>
                          <img src={s.image} alt={s.name} width="100%" className="rounded-0" />
                        </Col>
                      </Row>
                    </Container>
                  </Card>
                </a>
              </Col>
            ))}
          </Row>

          <Row>
            <Col lg={12}>
              <h1
                className="quote-text  text-center text-white"
                style={{ fontSize: size ? "6rem" : "3rem" }}
                text-center
              >
                2023 SPONSORS
              </h1>
            </Col>
          </Row>

          {Object.keys(rows).map((r) => {
            const subRows = groupBy("type")(rows[r]);
            return Object.keys(subRows).map((subRow) => (
              <Row>
                {subRows[subRow].map((s) => (
                  <Col lg={s.size} xs={6} className={"ml-auto mr-auto p-1"}>
                    <a href={s.url} target="_SPONSORS2023" rel="noreferrer">
                      <img src={s.image} alt={s.name} width="100%" className="rounded-0" />
                      <p className="text-400 mt-2 text-white text-center">{s.subtype}</p>
                    </a>
                  </Col>
                ))}
              </Row>
            ));
          })}
        </Container>
      </div>
    </>
  );
}

export default Sponsor;
