// reactstrap components
import { Container, Col, Row, Button } from "reactstrap";

import React from "react";

function LandingPageHeader({ home }) {
  const size =
    document.body.clientWidth >= 928 || document.documentElement.clientWidth >= 928 ? true : false;

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      <div
        className="page-header"
        style={{
          backgroundColor: "#000",
          backgroundImage: `url(${require(`assets/images/${size ? "BG1.png" : "BG1mobile.png"}`)})`,
          backgroundSize: size ? "100%" : "cover",
          backgroundRepeat: "no-repeat",

          // marginTop: size ? "7.8rem" : "0rem",
          paddingBottom: size ? "8.2rem" : "10rem",
        }}
        data-parallax={true}
      >
        {/* <div className="overlay-primary"></div> */}

        <Container>
          <Row className="align-items-center justify-content-start">
            <Col md={6} xs={12} className="text-center">
              {/* <h5 className="text-white">Organized By</h5> */}
              <img
                // src={require("assets/images/kt.png")}
                src="/images/KTEvents.png"
                width="100%"
                alt="main logo"
                className="my-3"
                style={{ maxWidth: 200 }}
              />
              <h4 className="text-white mt-0 mb-2">Presents</h4>
              <img
                src={require("assets/images/LogoNew.png")}
                width="100%"
                alt="main logo"
                className=""
              />
              <Container fluid className="pt-3">
                <Row>
                  <Col lg={12} className="pb-3 px-0 text-center">
                    <Button
                      href="/register"
                      className="navbar-item-custom text-400 text-dark rounded-0 "
                      style={{
                        background: "#e65e01",
                        border: "2px solid #000",
                      }}
                      size="lg"
                    >
                      <span
                        style={{
                          color: "#000",
                          textTransform: "none",
                          fontWeight: 700,
                          fontSize: "16px",
                        }}
                        className="text-600"
                      >
                        REGISTER NOW <i class="fa fa-arrow-right" />
                      </span>
                    </Button>
                    <Button
                      href="/sponsor"
                      className="navbar-item-custom text-400 text-dark rounded-0"
                      style={{
                        background: "#e65e01",
                        border: "2px solid #000",
                      }}
                      size="lg"
                    >
                      <span
                        style={{
                          color: "#000",
                          textTransform: "none",
                          fontWeight: 700,
                          fontSize: "16px",
                        }}
                        className="text-600"
                      >
                        BECOME A SPONSOR
                        <i class="fa fa-arrow-right" />
                      </span>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default LandingPageHeader;
